import { Button } from "@/components/ui/button"
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Form } from "@/components/ui/form"
import { InputFormField } from "@/components/ui/input/input-form-field"
import { SelectFormField } from "@/components/ui/select/select-form-field"
import { Database, Tables } from "@/lib/database.types"
import { DataTable } from "@/src/components/DataTable"
import { Svgs } from "@/src/components/svgs"
import { useDialogHandler } from "@/src/hooks/use-dialog-handler"
import { zodResolver } from "@hookform/resolvers/zod"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { ColumnDef } from "@tanstack/react-table"
import { ArrowRight, Loader2, MoreVertical, Pencil, Plus, Trash } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"
import { z } from "zod"
import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import Template from "@/src/components/Template.tsx"

const roleOptions: {
  label: string
  value: Database["public"]["Enums"]["family_role_type"]
}[] = [
  { label: "Self", value: "CONTACT" },
  { label: "Spouse", value: "SPOUSE" },
  // { label: "Parent", value: "PARENT" },
  { label: "Child", value: "CHILD" },
  // { label: "Grandparent", value: "GRANDPARENT" },
  // { label: "Grandchild", value: "GRANDCHILD" },
  // { label: "Sibling", value: "SIBLING" },
  // { label: "Aunt or Uncle", value: "AUNT_OR_UNCLE" },
  // { label: "Other", value: "OTHER" },
]

const roleMap = new Map(roleOptions.map(({ value, label }) => [value, label]))

const addApplicantFormSchema = z.object({
  id: z.string().optional(),
  givenName: z.string().min(1, "Name is required"),
  surname: z.string().min(1, "Surname is required"),
  birth_date: z.string(),
  relationshipToContactPerson: z.enum(
    roleOptions.map(({ value }) => value) as [Database["public"]["Enums"]["family_role_type"]],
    { message: "Invalid relationship type" },
  ),
  gender: z.enum(["Male", "Female"], { message: "Invalid gender" }),
})

export const DefaultColumns: readonly ColumnDef<Tables<"applicant">>[] = [
  {
    accessorKey: "given_name",
    header: "Given Name",
    cell: ({ row }) => <span className="font-bold">{row.original.given_name}</span>,
  },
  {
    accessorKey: "family_name",
    header: "Surname",
    cell: ({ row }) => <span className="font-bold">{row.original.family_name}</span>,
  },
  {
    accessorKey: "birth_date",
    header: "Birth Date",
    cell: ({ row }) => <span className="font-bold">{row.getValue("birth_date")}</span>,
  },
  {
    accessorKey: "female",
    header: "Sex",
    cell: ({ row }) => <span className="font-bold">{row.getValue("female") ? "Female" : "Male"}</span>,
  },
  {
    accessorKey: "family_role",
    header: "Relationship",
    cell: ({ row }) => <span className="font-bold">{roleMap.get(row.getValue("family_role"))}</span>,
  },
]

const ListOfApplicantsPage = () => {
  useDocumentTitle(["Applicants"])
  const navigate = useNavigate()
  const supabase = useSupabaseClient<Database>()
  const fromApplication = useFromApplication()
  const urlApplicationId = useParams().application_id

  // We have to use application id for inserting into applicants.
  // However, we get the application id "for free" anyway when we fetch applicants using the graphql api
  const [applicationId, setApplicationId] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateForm, setIsUpdateForm] = useState(false)
  const [persons, setPersons] = useState<Tables<"applicant">[]>([])

  const { isDialogOpen, onOpenChange, onOpen, onClose } = useDialogHandler()

  const addApplicantForm = useForm<z.infer<typeof addApplicantFormSchema>>({
    resolver: zodResolver(addApplicantFormSchema),
    defaultValues: {
      givenName: "",
      surname: "",
      birth_date: "",
      relationshipToContactPerson: "CONTACT",
      gender: "Male",
    },
  })

  useEffect(() => {
    async function fetchApplicants() {
      const { data, error } = await fromApplication.select("id, applicant ( * ) ").single()

      if (error) {
        console.error("Error fetching applicants", error)
      }

      if (data) {
        setApplicationId(data.id)
        setPersons(data.applicant ?? [])
      }

      setIsLoading(false)
    }

    fetchApplicants()
  }, [fromApplication, supabase])

  useEffect(() => {
    if (!isDialogOpen && isUpdateForm) {
      addApplicantForm.reset({
        givenName: "",
        birth_date: "",
        relationshipToContactPerson: "CONTACT",
        gender: "Male",
      })

      setIsUpdateForm(false)
    }
  }, [addApplicantForm, isDialogOpen, isUpdateForm])

  const handleAddApplicant = useCallback(
    async (values: z.infer<typeof addApplicantFormSchema>) => {
      if (isUpdateForm) {
        if (values.id) {
          const { data, error } = await supabase
            .from("applicant")
            .update({
              given_name: values.givenName,
              family_name: values.surname,
              birth_date: values.birth_date,
              female: values.gender === "Female",
              family_role: values.relationshipToContactPerson,
            })
            .eq("id", values.id)
            .select("*")
            .single()

          if (error) {
            console.error(error)
            toast.error("Could not update applicant")
          }

          if (data) setPersons((prev) => prev.map((person) => (data.id === person.id ? data : person)))
        }
      } else {
        if (applicationId == null) {
          // should be unreachable. Here to satisfy type checker
          toast.error("Could not add applicant, no application found")
          return
        }

        const { data, error } = await supabase
          .from("applicant")
          .insert({
            given_name: values.givenName,
            family_name: values.surname,
            birth_date: values.birth_date,
            female: values.gender === "Female",
            application_id: applicationId,
            family_role: values.relationshipToContactPerson,
          })
          .select("*")
          .maybeSingle()

        if (error) {
          console.error(error)
          toast.error("Could not add applicant")
          return
        }

        if (data) setPersons((prev) => [...prev, data])
      }

      addApplicantForm.reset()

      onClose()
    },
    [addApplicantForm, applicationId, isUpdateForm, onClose, supabase],
  )

  const handleDeleteApplicant = useCallback(
    async (id: string) => {
      const { error } = await supabase.from("applicant").delete().eq("id", id)
      // .eq("application_id", application_id!)

      if (error) {
        toast.error(error.message)
        return
      }

      setPersons((prev) => prev.filter((person) => person.id !== id))
    },
    [supabase],
  )

  const columns: ColumnDef<Tables<"applicant">>[] = [
    ...DefaultColumns,
    {
      accessorKey: "action",
      header: "",
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="ghost">
                <MoreVertical className="w-5 h-5" />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent side="right" align="start">
              <DropdownMenuItem
                onClick={() => {
                  setIsUpdateForm(true)

                  addApplicantForm.reset({
                    givenName: row.original.given_name!,
                    surname: row.original.family_name!,
                    birth_date: row.original.birth_date!,
                    relationshipToContactPerson: row.original.family_role!,
                    gender: row.original.female ? "Female" : "Male",
                    id: row.original.id,
                  })

                  onOpen()
                }}
              >
                <Pencil className="mr-2 w-4 h-4" />
                <span>Edit Details</span>
              </DropdownMenuItem>

              <DropdownMenuItem onClick={() => handleDeleteApplicant(row.original.id)}>
                <Trash className="mr-2 w-4 h-4" />
                <span>Delete Person</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]

  return (
    <>
      <Template slug="apply/_/applicants#1" />

      {isLoading ? (
        <Loader2 className="animate-spin w-fit mx-auto" />
      ) : persons.length === 0 ? (
        <div className="h-[500px] bg-white border rounded-sm p-8 grid place-content-center place-items-center gap-8">
          <Svgs.home_add_list_applicants />

          <p>You don’t have any applicants yet. Click on “Add Person” to get started.</p>

          <Button onClick={onOpen}>
            <Plus className="w-5 h-5" />
            Add Person
          </Button>
        </div>
      ) : (
        <div className="flex flex-col items-end gap-4">
          <DataTable
            TopActionButtons={() => <div />}
            EndActionButtons={() => (
              <Button onClick={onOpen}>
                <Plus className="w-5 h-5" />
                Add Person
              </Button>
            )}
            columns={columns}
            data={persons}
            hideOtherActions
            hidePagination
          />

          <Button onClick={() => navigate(`/apply/${urlApplicationId}/participation`)}>
            Continue
            <ArrowRight className="w-5 h-5" />
          </Button>
        </div>
      )}

      <Dialog open={isDialogOpen} onOpenChange={onOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Person</DialogTitle>
          </DialogHeader>

          <Form {...addApplicantForm}>
            <form onSubmit={addApplicantForm.handleSubmit(handleAddApplicant)} className="grid gap-4">
              <InputFormField label="Given Name" name="givenName" placeholder="John" required />

              <InputFormField label="Surname" name="surname" placeholder="Doe" required />

              <InputFormField label="Date of Birth" name="birth_date" type="date" placeholder="MM/DD/YY" required />

              <SelectFormField
                label="Gender"
                name="gender"
                placeholder="Select a gender"
                options={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                ]}
                required
              />

              <SelectFormField
                label="Relationship to contact person"
                name="relationshipToContactPerson"
                placeholder="Select a relationship type"
                options={roleOptions}
                required
              />

              <DialogFooter className="w-full grid grid-cols-2">
                <DialogClose type="button" asChild>
                  <Button type="button" variant="white">
                    Close
                  </Button>
                </DialogClose>

                <Button type="submit">{isUpdateForm ? "Update" : "Save"}</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ListOfApplicantsPage
